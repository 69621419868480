import { formatDate } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { fairAny } from 'dku-frontend-core';
import { cloneDeep } from 'lodash';
import { ChartColumnTypeUtilsService, ChartFilterUtilsService, NumberFormatterService } from '.';
import { AxisDef, ChartFilter, ColumnSummary, FilterFacet, PivotTableResponse, ChartsOnDatasetDataSpec } from 'generated-sources';
import { DateUtilsService } from '@shared/services';
import { FacetUiState, FilterTmpData, FilterTmpDataValue, FrontendChartFilter, FrontendChartFilterTypeProperties } from '../models';

type PivotRequestErrorCallback = (data: Record<string, unknown> | null, status: number, headers: unknown, config?: unknown, statusText?: string) => void;
export interface GetPivotRequestOptions {
    projectKey: string;
    dataSpec: ChartsOnDatasetDataSpec;
    requestedSampleId: string;
    onError: PivotRequestErrorCallback;
}

type AlphanumFilterSelectionType = ChartFilter.FilterSelectionType.MULTI_SELECT | ChartFilter.FilterSelectionType.SINGLE_SELECT;

@Injectable({
    providedIn: 'root'
})
/**
 * Everything about mapping 'ChartFilter.java' to 'filter-row.html' and vice-versa.
 * (!) This service previously was in static/dataiku/js/simple_report/services/chart-filters.service.js
 */
export class ChartFiltersService {
    private readonly AVAILABLE_RELATIVE_DATE_FILTER_DATE_PARTS = new Set(this.chartFilterUtilsService.getDateRelativeFilterParts().map(([value]) => value));
    private readonly logger: { error: (msg: string) => void };
    constructor(
        private readonly chartFilterUtilsService: ChartFilterUtilsService,
        private readonly chartColumnTypeUtilsService: ChartColumnTypeUtilsService,
        private readonly numberFormatterService: NumberFormatterService,
        private readonly dateUtilsService: DateUtilsService,
        @Inject(LOCALE_ID) private readonly locale: string,
        @Inject('ChartDataUtils') private readonly chartDataUtilsService: fairAny,
        @Inject('$location') private readonly $location: fairAny,
        @Inject('ChartRequestComputer') private readonly chartRequestComputer: fairAny,
        @Inject('FilterFacetsService') private readonly filterFacetsService: fairAny,
        @Inject('Logger') private loggerFactory: fairAny
    ) {
        this.logger = this.loggerFactory({ serviceName: 'ChartFilters', objectName: 'Service' });
    }

    canClearAllFilters(filtersTmpData: FilterTmpData[]): boolean {
        return filtersTmpData && filtersTmpData.find(filter => filter.$canBeCleared) !== undefined;
    }

    getAllFiltersSummary(filtersTmpData: FilterTmpData[]): string {
        const filtersCount = filtersTmpData.length;
        let deactivatedFiltersCount = 0;

        filtersTmpData && filtersTmpData.forEach(filter => {
            if (!filter.active) {
                deactivatedFiltersCount++;
            }
        });

        if (deactivatedFiltersCount === 0) {
            return this.getDefaultSummary(filtersCount);
        }

        return this.getSummaryForSomeDeactivatedFilters(filtersCount, deactivatedFiltersCount);
    }

    clearFacet(filterTmpData: FilterTmpData): void {
        if (this.chartColumnTypeUtilsService.isNumericalColumnType(filterTmpData.columnType) || this.chartColumnTypeUtilsService.isDateColumnType(filterTmpData.columnType)) {
            this.switchFilterSelectionTypeToRange(filterTmpData);
            if (filterTmpData.minValue !== undefined) {
                filterTmpData.minValue = null;
            }
            if (filterTmpData.maxValue !== undefined) {
                filterTmpData.maxValue = null;
            }
            if (filterTmpData.timezone !== undefined) {
                filterTmpData.timezone = 'UTC';
            }
            filterTmpData.excludeOtherValues = this.getDefaultExcludeOtherValues(filterTmpData.isAGlobalFilter, filterTmpData?.values);
        } else if (this.chartColumnTypeUtilsService.isAlphanumColumnType(filterTmpData.columnType)) {
            const defaultExcludeOtherValues = this.getDefaultExcludeOtherValues(filterTmpData.isAGlobalFilter, filterTmpData?.values);
            this.switchFilterSelectionTypeToAlphanum(filterTmpData, defaultExcludeOtherValues, ChartFilter.FilterSelectionType.MULTI_SELECT);
            filterTmpData.values?.forEach(value => value.included = true);
        } else {
            this.logger.error('Unsupported filter encountered while clearing facet');
        }
    }

    clearAllFacets(filtersTmpData: FilterTmpData[]): void {
        for (const filterTmpData of filtersTmpData) {
            this.clearFacet(filterTmpData);
        }
    }

    /**
     * Initializes filtersTmpData (the displayed filters) from pivot-response and current filters data.
     * @param responseData            - Filters available from pivot-response for a given source dataset (computed data)
     * @param filters                 - Filters (data to store)
     * @param productShortName
     * @param overrideWithResponse    - Override filterTmpData min and max values from the reponse (optional)
     *
     * @return filters display data (temporary data used only in view)
     */
    getFiltersTmpData(responseData: { result: { pivotResponse: PivotTableResponse } }, filters: FrontendChartFilter[], productShortName: string, overrideWithResponse = false): FilterTmpData[] {
        if (!responseData || !responseData.result.pivotResponse.filterFacets.length) {
            return [];
        }
        const filtersTmpData: FilterTmpData[] = [];
        const shouldFold = filters && filters.length > 3;

        for (let fIdx = 0; fIdx < filters.length; fIdx++) {
            const filter = filters[fIdx];
            const responseFacet = responseData.result.pivotResponse.filterFacets[fIdx];
            const filterTmpData: Partial<FilterTmpData> = {
                column: filter.column,
                active: filter.active === undefined ? true : filter.active,
                $folded: filter.$folded === undefined ? (filter.isAGlobalFilter && shouldFold) : filter.$folded,
                excludeOtherValues: this.computeExcludeOtherValues(filter, responseFacet),
                allValuesInSample: filter.allValuesInSample === undefined ? false : filter.allValuesInSample,
                isAGlobalFilter: filter.isAGlobalFilter,
                filterType: filter.filterType,
                columnType: filter.columnType
            };

            if (filter.filterSelectionType) {
                filterTmpData.filterSelectionType = filter.filterSelectionType;
            } else if (this.chartFilterUtilsService.isAlphanumericalFilter(filter)) {
                filterTmpData.filterSelectionType = ChartFilter.FilterSelectionType.MULTI_SELECT;
            } else if (this.chartFilterUtilsService.isNumericalFilter(filter)) {
                filterTmpData.filterSelectionType = ChartFilter.FilterSelectionType.RANGE_OF_VALUES;
            }

            this.setFacetValues(responseFacet, filter, overrideWithResponse, filterTmpData);
            if (filter.isAGlobalFilter) {
                filterTmpData.$summary = this.getFacetSummary(filter, filterTmpData as FilterTmpData);
                filterTmpData.$canBeCleared =
                    this.hasChangedFacetSelectionType(filterTmpData as FilterTmpData) ||
                    this.hasChangedExcludeOtherValuesOption(filterTmpData as FilterTmpData) ||
                    this.hasChangedFacetValues(filter, filterTmpData as FilterTmpData);
            }

            if (responseFacet.isTruncated) {
                filterTmpData.warning = `Facet values are incomplete (${this.getFriendlyNameForEngine(responseData.result.pivotResponse.engine, productShortName)} engine limit reached)`;
            }
            filtersTmpData.push(filterTmpData as FilterTmpData);
        }
        return filtersTmpData;
    }

    /**
     * Updates current filters with filtersTmpData (data from view).
     * @param filtersTmpData    - Filters display data (temporary data used only in view)
     * @param filters           - Filters to be modified (data to store)
     * @param isAGlobalFilter   - if true, set specific properties used on dashboard filters
     */
    applyTmpDataToFilters(filtersTmpData: FilterTmpData[], filters: FrontendChartFilter[], isAGlobalFilter = false): void {
        if (!filtersTmpData || !filters) {
            return;
        }

        for (let fIdx = 0; fIdx < filters.length && fIdx < filtersTmpData.length; fIdx++) {
            const filter = filters[fIdx];
            const filterTmpData = filtersTmpData[fIdx];

            filter.filterSelectionType = filterTmpData.filterSelectionType;
            filter.filterType = filterTmpData.filterType;
            filter.columnType = filterTmpData.columnType;
            filter.isAGlobalFilter = isAGlobalFilter;
            filter.active = filterTmpData.active;
            filter.excludeOtherValues = filterTmpData.excludeOtherValues;
            filter.allValuesInSample = filterTmpData.allValuesInSample;
            filter.$folded = filterTmpData.$folded;
            if (this.chartColumnTypeUtilsService.isDateColumnType(filter.columnType)) {
                filter.dateFilterType = filterTmpData.dateFilterType;
                filter.dateFilterPart = filterTmpData.dateFilterPart;

                switch (filter.dateFilterType) {
                    case ChartFilter.DateFilterType.RELATIVE:
                        filter.dateFilterOption = filterTmpData.dateFilterOption;
                        filter.minValue = filterTmpData.minValue;
                        filter.maxValue = filterTmpData.maxValue;
                        break;
                    case ChartFilter.DateFilterType.RANGE:
                        filter.timezone = filterTmpData.timezone;
                        break;
                }
            }

            if (this.chartFilterUtilsService.isAlphanumericalFilter(filter)) {
                delete filter.minValue;
                delete filter.maxValue;

                if (filterTmpData.excludeOtherValues) {
                    filter.selectedValues = this.getFacetValues(filterTmpData, true);
                    delete filter.excludedValues;
                } else {
                    filter.excludedValues = this.getFacetValues(filterTmpData, false);
                    delete filter.selectedValues;
                }
                filter.$totalValuesCount = filterTmpData.values ? filterTmpData.values.length : 0;
            } else if (this.chartFilterUtilsService.isNumericalFilter(filter)) {
                delete filter.selectedValues;
                delete filter.excludedValues;

                if (isAGlobalFilter) {
                    filter.minValue = filterTmpData.minValue;
                    filter.maxValue = filterTmpData.maxValue;
                } else {
                    filter.minValue = filterTmpData.minValue !== filterTmpData.response.minValue ? filterTmpData.minValue : null;
                    filter.maxValue = filterTmpData.maxValue !== filterTmpData.response.maxValue ? filterTmpData.maxValue : null;
                }
            }
        }
    }

    /**
     * Updates filterTmpData according to the new selection type.
     * @param newSelectionType          - The new selection type be applied.
     * @param filterTmpData             - The filter on which to apply the new type.
     * @param filters                   - The list of all filters.
     * @param filterIndex               - The index of the filter concerned by the selection type change.
     * @param getPivotResponseOptions   - The options to make the get-pivot-response request.
     */
    switchFilterSelectionType(newSelectionType: ChartFilter.FilterSelectionType, filterTmpData: FilterTmpData, filters: ChartFilter[], filterIndex: number, getPivotResponseOptions: GetPivotRequestOptions): void {
        // set the default include/exclude behavior depending on the selection type
        switch (newSelectionType) {
            case ChartFilter.FilterSelectionType.MULTI_SELECT:
                this.switchFilterSelectionTypeToMultiSelect(filterTmpData, filters, filterIndex, getPivotResponseOptions);
                break;
            case ChartFilter.FilterSelectionType.SINGLE_SELECT:
                this.switchFilterSelectionTypeToSingleSelect(filterTmpData, filters, filterIndex, getPivotResponseOptions);
                break;
            case ChartFilter.FilterSelectionType.RANGE_OF_VALUES:
                this.switchFilterSelectionTypeToRange(filterTmpData);
                break;
        }
    }

    /**
     * Removes the filters query parameter in the URL.
     */
    clearFiltersSearchParams(): void {
        this.$location.search('filters', null);
    }

    /**
     * Initializes a filter.
     */
    autocompleteFilter(filter: Partial<ChartFilter>, usableColumns: ColumnSummary.UsableColumn[] = [], isAGlobalFilter = false): void {
        const column = usableColumns.find(col => col.column === filter.column);
        let type: AxisDef.Type | undefined = undefined;
        if (column && column.type !== filter.columnType) {
            type = column.type as AxisDef.Type;
            filter.columnType = column.type as AxisDef.Type;
            filter.filterType = filter.filterType || `${column.type}_FACET` as ChartFilter.FilterType;
            if (filter.columnType === 'DATE') {
                filter.dateFilterType = ChartFilter.DateFilterType.RANGE;
            }
        }
        if (filter.isA !== 'filter') {
            type = filter.columnType || type;
            filter.columnType = type;
            if (type) {
                filter.filterType = filter.filterType || `${type}_FACET` as ChartFilter.FilterType;
            }
            filter.isA = 'filter';
            if (filter.columnType === 'DATE') {
                filter.dateFilterType = ChartFilter.DateFilterType.RANGE;
            }
        }
        if (filter.allValuesInSample === undefined) {
            filter.allValuesInSample = false;
        }
        if (filter.isAGlobalFilter === undefined) {
            filter.isAGlobalFilter = isAGlobalFilter;
        }
        if (this.chartFilterUtilsService.isAlphanumericalFilter(filter as FrontendChartFilterTypeProperties)) {
            /*
             * For dashboards, the excludeOtherValues value is dynamic and computed from the facet values.
             * As we don't have the values here, we don't initialise it.
             */
            if (!isAGlobalFilter) {
                filter.excludeOtherValues = filter.excludeOtherValues === undefined ? false : filter.excludeOtherValues;
                if (filter.excludeOtherValues) {
                    filter.selectedValues = filter.selectedValues || null;
                } else {
                    filter.excludedValues = filter.excludedValues || {};
                }
            }
            filter.filterSelectionType = filter.filterSelectionType || ChartFilter.FilterSelectionType.MULTI_SELECT;
        } else if (this.chartFilterUtilsService.isNumericalFilter(filter as FrontendChartFilterTypeProperties)) {
            filter.filterSelectionType = filter.filterSelectionType || ChartFilter.FilterSelectionType.RANGE_OF_VALUES;

            if (this.chartColumnTypeUtilsService.isDateColumnType(filter.columnType)) {
                filter.timezone = filter.timezone || 'UTC';
            }
        }
    }

    /**
     * Builds the request object for a get-pivot-response API call of type filters.
     * @param filters
     * @param requestParams
     * @returns
     */
    buildFiltersRequest(filters: ChartFilter[], requestParams?: Record<string, unknown>): Record<string, unknown> {
        const requestDefinition = {
            type: 'filters',
            filters
        };
        const request = this.chartRequestComputer.compute(requestDefinition);
        for (const [key, value] of Object.entries(requestParams || {})) {
            request[key] = value;
        }

        return request;
    }

    /**
     * Updates filterTmpData according to the new date filter part.
     */
    switchDateFilterPart(dateFilterPart: ChartFilter.DateFilterPart, filterTmpData: FilterTmpData): void {
        // Set the values to null so that the backend returns all the available values for the newly selected date part.
        filterTmpData.values = null;
        filterTmpData.dateFilterPart = dateFilterPart;
    }

    /**
     * Updates filterTmpData according to the new date filter type.
     */
    switchDateFilterType(dateFilterType: ChartFilter.DateFilterType, filterTmpData: FilterTmpData, filters: ChartFilter[], filterIndex: number, getPivotResponseOptions: GetPivotRequestOptions): void {
        switch (dateFilterType) {
            case ChartFilter.DateFilterType.RANGE:
                this.switchDateFilterTypeToRange(filterTmpData, filters, filterIndex, getPivotResponseOptions);
                break;
            case ChartFilter.DateFilterType.RELATIVE:
                this.switchDateFilterTypeToRelativeRange(filterTmpData, filters, filterIndex, getPivotResponseOptions);
                break;
            case ChartFilter.DateFilterType.PART:
                this.switchDateFilterTypeToDatePart(filterTmpData, filters, filterIndex, getPivotResponseOptions);
                break;
        }
    }

    /**
     * Computes the facet UI states from filterTmpData.
     * @param filtersTmpData
     * @returns
     */
    getFacetUiStates(filtersTmpData: FilterTmpData[]): FacetUiState[] {
        return (filtersTmpData || []).map(tmpData => {
            const facetUiState: FacetUiState = {};
            const isResponseAlphanumerical = !!tmpData.response && !!tmpData.response.values.length;

            if (tmpData.filterSelectionType === ChartFilter.FilterSelectionType.RANGE_OF_VALUES) {
                const lb = !isResponseAlphanumerical ? tmpData.response.minValue : undefined;
                const ub = !isResponseAlphanumerical ? tmpData.response.maxValue : undefined;

                facetUiState.sliderLowerBound = lb !== undefined ? lb : facetUiState.sliderLowerBound;
                facetUiState.sliderUpperBound = ub !== undefined ? ub : facetUiState.sliderUpperBound;
                facetUiState.sliderModelMin = tmpData.minValue;
                facetUiState.sliderModelMax = tmpData.maxValue;

                if (this.chartColumnTypeUtilsService.isNumericalColumnType(tmpData.columnType) && facetUiState.sliderModelMax != null && facetUiState.sliderModelMin != null) {
                    // 10000 ticks
                    let sliderStep = Math.round(10000 * (facetUiState.sliderModelMax - facetUiState.sliderModelMin)) / 100000000;
                    // Handle min=max
                    sliderStep = sliderStep === 0 ? 1 : sliderStep;

                    const sliderDecimals = Math.max(String(sliderStep - Math.floor(sliderStep)).length - 2, 0);

                    facetUiState.sliderStep = sliderStep;
                    facetUiState.sliderDecimals = sliderDecimals;
                }
            }
            if (tmpData.filterType === ChartFilter.FilterType.DATE_FACET) {
                facetUiState.dateFilterType = tmpData.dateFilterType;
                if (tmpData.dateFilterType === ChartFilter.DateFilterType.RANGE) {
                    const response = tmpData.response || {};
                    const minValue = tmpData.minValue != undefined ? tmpData.minValue : (!isResponseAlphanumerical ? response.minValue : null);
                    const maxValue = tmpData.maxValue != undefined ? tmpData.maxValue : (!isResponseAlphanumerical ? response.maxValue : null);

                    facetUiState.timezoneDateRangeModel = tmpData.timezone || 'UTC';
                    facetUiState.fromDateRangeModel = minValue !== null ? this.dateUtilsService.convertDateToTimezone(new Date(minValue), facetUiState.timezoneDateRangeModel) : undefined;
                    facetUiState.toDateRangeModel = maxValue !== null ? this.dateUtilsService.convertDateToTimezone(new Date(maxValue), facetUiState.timezoneDateRangeModel) : undefined;
                } else {
                    facetUiState.dateFilterPart = tmpData.dateFilterPart;
                }
            }

            return facetUiState;
        });
    }

    onDateRangeChange(facetUiState: FacetUiState, filterTmpData: FilterTmpData) {
        if (!filterTmpData) {
            return;
        }

        const from = facetUiState.fromDateRangeModel;
        const to = facetUiState.toDateRangeModel;
        // If a boundary is undefined it means that this boundary is invalid.
        if (from === undefined || to === undefined) {
            return;
        }
        const tz = facetUiState.timezoneDateRangeModel;

        if (tz) {
            filterTmpData.timezone = tz;
            filterTmpData.minValue = from != null ? this.dateUtilsService.convertDateFromTimezone(from, tz).getTime() : null;
            filterTmpData.maxValue = to != null ? this.dateUtilsService.convertDateFromTimezone(to, tz).getTime() : null;
        }
    }

    private getFriendlyNameForEngine(engineName: string, productShortName: string): string {
        if (engineName === 'LINO') {
            return productShortName;
        }
        if (engineName === 'SQL') {
            return 'In-database';
        }
        return engineName;
    }

    private isNumberWithinRange(value: number | null | undefined, responseFacet: FilterFacet) {
        return (typeof value === 'number' && value >= responseFacet.minValue && value <= responseFacet.maxValue) || value === null;
    }

    private hasWithinRangeMinAndMax(filter: ChartFilter, responseFacet: FilterFacet) {
        return filter && this.isNumberWithinRange(filter.minValue, responseFacet) && this.isNumberWithinRange(filter.maxValue, responseFacet);
    }

    private hasChangedAlphanumericalValue(filter: ChartFilter, tmpData: Record<string, fairAny>) {
        if (!filter || !this.chartFilterUtilsService.isAlphanumericalFilter(filter)) {
            return false;
        }
        if (filter.excludeOtherValues) {
            // For multi select filters, a filter has changed if all values aren't selected.
            if (filter.filterSelectionType === ChartFilter.FilterSelectionType.MULTI_SELECT) {
                return Object.keys(filter.selectedValues || {}).length !== tmpData.values.length;
            }
            // For single select filters, a filter has changed if the selected value isn't the first one.
            return Object.keys(filter.selectedValues || {})[0] !== tmpData.values[0].id;
        } else {
            /*
             * Single select filters cannot be in include other values mode.
             * For multi select filters, a filter has changed if some values are excluded.
             */
            return Object.keys(filter.excludedValues || {}).length > 0;
        }
    }

    private hasChangedNumericalValue(filter: ChartFilter, tmpData: FilterTmpData) {
        if (!this.chartFilterUtilsService.isNumericalFilter(filter)) {
            return false;
        }
        if (filter.isAGlobalFilter) {
            // On dashboard filters, if range has been modified it is necessarily within the current facet available range
            const hasChangedMinOrMax = (filter.minValue !== tmpData.response.minValue && filter.minValue !== null) || (filter.maxValue !== tmpData.response.maxValue && filter.maxValue !== null);
            return hasChangedMinOrMax && this.hasWithinRangeMinAndMax(filter, tmpData.response);
        } else {
            // On charts filters (explore and insights), no change means values have not been set
            return typeof filter.minValue !== 'number' && typeof filter.maxValue !== 'number';
        }
    }

    private hasChangedTimezone(filter: ChartFilter):boolean {
        return filter.timezone !== 'UTC';
    }

    private hasChangedRelativeDateValue(filter: ChartFilter): boolean {
        return filter.dateFilterOption !== ChartFilter.DateRelativeOption.THIS;
    }

    private hasChangedFacetValues(filter: ChartFilter, tmpData: FilterTmpData): boolean {
        if (this.chartFilterUtilsService.isAlphanumericalFilter(filter)) {
            return this.hasChangedAlphanumericalValue(filter, tmpData);
        } else if (this.chartFilterUtilsService.isDateRangeFilter(filter)){
            return this.hasChangedNumericalValue(filter, tmpData) || this.hasChangedTimezone(filter);
        } else if (this.chartFilterUtilsService.isNumericalFilter(filter)) {
            return this.hasChangedNumericalValue(filter, tmpData);
        } else if (this.chartFilterUtilsService.isRelativeDateFilter(filter)) {
            return this.hasChangedRelativeDateValue(filter);
        }
        this.logger.error('Unsupported filter encountered while determining if filter changed');
        return false;
    }

    private hasChangedExcludeOtherValuesOption(filterTmpData: FilterTmpData): boolean {
        return this.getDefaultExcludeOtherValues(filterTmpData.isAGlobalFilter, filterTmpData?.response?.values) !== filterTmpData.excludeOtherValues;
    }

    private hasChangedFacetSelectionType(filterTmpData: FilterTmpData): boolean {
        if (this.chartColumnTypeUtilsService.isNumericalColumnType(filterTmpData.columnType)) {
            return this.hasChangedNumericalColumnSelectionType(filterTmpData);
        } else if (this.chartColumnTypeUtilsService.isDateColumnType(filterTmpData.columnType)) {
            return this.hasChangedDateColumnSelectionType(filterTmpData);
        } else if (this.chartColumnTypeUtilsService.isAlphanumColumnType(filterTmpData.columnType)) {
            return this.hasChangedAlphanumColumnSelectionType(filterTmpData);
        }
        this.logger.error('Unsupported filter encountered while determining if filter selection type changed');
        return false;
    }

    private hasChangedNumericalColumnSelectionType(filterTmpData: FilterTmpData): boolean {
        return filterTmpData.filterType !== ChartFilter.FilterType.NUMERICAL_FACET
        || filterTmpData.filterSelectionType !== ChartFilter.FilterSelectionType.RANGE_OF_VALUES;
    }

    private hasChangedDateColumnSelectionType(filterTmpData: FilterTmpData): boolean {
        return filterTmpData.dateFilterType !== ChartFilter.DateFilterType.RANGE
        || filterTmpData.filterSelectionType !== ChartFilter.FilterSelectionType.RANGE_OF_VALUES;
    }

    private hasChangedAlphanumColumnSelectionType(filterTmpData: FilterTmpData): boolean {
        return filterTmpData.filterType !== ChartFilter.FilterType.ALPHANUM_FACET
        || filterTmpData.filterSelectionType !== ChartFilter.FilterSelectionType.MULTI_SELECT;
    }

    private getFiltersCountLabel(filtersCount: number) {
        return `${filtersCount} filter${filtersCount > 1 ? 's' : ''}`;
    }

    private getDeactivatedFiltersCountLabel(deactivatedFiltersCount: number) {
        return `${deactivatedFiltersCount} disabled`;
    }

    private getDefaultSummary(filtersCount: number) {
        return `${this.getFiltersCountLabel(filtersCount)}`;
    }

    private getSummaryForSomeDeactivatedFilters(filtersCount: number, deactivatedFiltersCount: number) {

        if (deactivatedFiltersCount === filtersCount) {
            return `${this.getFiltersCountLabel(filtersCount)} (all disabled)`;
        }

        return `${this.getFiltersCountLabel(filtersCount)} (${this.getDeactivatedFiltersCountLabel(deactivatedFiltersCount)})`;

    }

    private getFacetSummary(filter: ChartFilter, tmpData: Pick<FilterTmpData, 'values' | 'response'>) {
        if (this.chartFilterUtilsService.isAlphanumericalFilter(filter)) {
            if (!tmpData.values || tmpData.values.length === 0) {
                return 'No value available';
            }
            let valuesCount = 0;
            const allValues = (filter.excludeOtherValues ? filter.selectedValues : filter.excludedValues) || {};
            const filteredValuesCount = Object.values(allValues).filter(included => included).length;
            if (filter.excludeOtherValues) {
                valuesCount = filteredValuesCount;
            } else {
                valuesCount = tmpData.values.length - filteredValuesCount;
            }

            if (valuesCount === 0) {
                return 'None selected';
            }

            return `${valuesCount} selected`;
        } else if (this.chartFilterUtilsService.isNumericalFilter(filter)) {
            if (tmpData.response.minValue === 0 && tmpData.response.maxValue === 0) {
                return 'No value available';
            }

            /*
             * Stored filter values can be out of range compared to new facets values received from response
             * so we always display the minimal range from both
             */
            const areMinAndMaxWithinRange = this.hasWithinRangeMinAndMax(filter, tmpData.response);
            let summaryValues;
            if (areMinAndMaxWithinRange) {
                summaryValues = {
                    minValue: filter.minValue != null ? filter.minValue : tmpData.response.minValue,
                    maxValue: filter.maxValue != null ? filter.maxValue : tmpData.response.maxValue
                };
            } else {
                summaryValues = tmpData.response;
            }

            let formatter;
            if (filter.columnType === 'DATE') {
                const dateDisplayUnit = this.chartDataUtilsService.computeDateDisplayUnit(summaryValues.minValue, summaryValues.maxValue);
                formatter = (value: number) => formatDate(value, dateDisplayUnit.dateFilterOption, this.locale, dateDisplayUnit.dateFilterOptionTimezone);
            } else {
                formatter = this.numberFormatterService.get(summaryValues.minValue, summaryValues.maxValue, 2);
            }
            return `${formatter(summaryValues.minValue)} to ${formatter(summaryValues.maxValue)}`;
        } else if (this.chartFilterUtilsService.isRelativeDateFilter(filter) && filter.dateFilterOption && filter.dateFilterPart) {
            return this.chartFilterUtilsService.computeRelativeDateLabel(filter.dateFilterOption, filter.dateFilterPart, filter.minValue != null ? filter.minValue : null, filter.maxValue != null ? filter.maxValue : null);
        }

        this.logger.error('Unsupported filter encountered while computing facet summary');
        return '';
    }

    /**
     * For an alphanumerical filter, returns all filter values that need to be tracked.
     * If the response contains only the relevant values, previsouly edited unrelevant values need to be tracked.
     */
    private getAllTrackedValues(responseFacet: FilterFacet, filter: ChartFilter, hasSamplingChanged: boolean, selectedValues: string[], excludedValues: string[]): Omit<FilterTmpDataValue, 'included'>[] {
        /*
         * If all values in sample are in the response facet, we know there cannot be additional values to track.
         * If the sampling changed, we also want to clear tracked values so as not to track values that may not be in the sample anymore.
         */
        if (filter.allValuesInSample || hasSamplingChanged) {
            return responseFacet.values as Omit<FilterTmpDataValue, 'included'>[];
        }
        // If only relevant values are in the response facet, the filter may contain unrelevant values that needs tracking.
        const responseFacetValueIds = new Set(responseFacet.values.map(({ id }) => id));
        const unrelevantValues = (filter.excludeOtherValues ? selectedValues : excludedValues)
            .filter(filterValue => !responseFacetValueIds.has(filterValue))
            .map(filterValue => ({ id: filterValue, label: filterValue, count: 0 }));

        return [
            ...responseFacet.values,
            ...unrelevantValues
        ];
    }

    /**
     * Determines whether if a filter value should be selected or not.
     */
    private shouldFilterValueBeSelected(value: Omit<FilterTmpDataValue, 'included'>, filter: ChartFilter, selectedValuesSet: Set<string>, excludedValuesSet: Set<string>, hasAValueAlreadyBeenIncluded: boolean): boolean {
        if (filter.filterSelectionType === ChartFilter.FilterSelectionType.SINGLE_SELECT && hasAValueAlreadyBeenIncluded === true) {
            return false;
        }

        const isNewFilter = !filter.selectedValues && !filter.excludedValues;
        if (isNewFilter) {
            return true;
        }

        const isInSelectedValues = selectedValuesSet.has(value.id);
        if (isInSelectedValues) {
            return true;
        }

        const isInExcludedValues = excludedValuesSet.has(value.id);
        if (isInExcludedValues) {
            return false;
        }

        return !filter.excludeOtherValues;
    }

    /**
     * Sets the filter facet state in `filterTmpData` for the filter UI.
     */
    private setFacetValues(responseFacet: FilterFacet, filter: FrontendChartFilter, hasSamplingChanged: boolean, filterTmpData: Partial<FilterTmpData>) {
        filterTmpData.response = responseFacet;
        filterTmpData.filterType = filter.filterType;

        if (this.chartFilterUtilsService.isAlphanumericalFilter(filter)) {
            this.setAlphanumericalFilterValues(responseFacet, filter, hasSamplingChanged, filterTmpData);
        } else if (this.chartFilterUtilsService.isDateRangeFilter(filter)) {
            this.setDateRangeFilterValues(responseFacet, filter, hasSamplingChanged, filterTmpData);
        } else if (this.chartFilterUtilsService.isNumericalRangeFilter(filter)) {
            this.setNumericalFilterValues(responseFacet, filter, hasSamplingChanged, filterTmpData);
        } else if (this.chartFilterUtilsService.isRelativeDateFilter(filter)) {
            this.setRelativeDateFilterValues(filter, filterTmpData);
        }

        if (filter.$isFromUrlQuery) {
            filterTmpData.$isFromUrlQuery = filter.$isFromUrlQuery;
            delete filter.$isFromUrlQuery;
        }
    }

    private setAlphanumericalFilterValues(responseFacet: FilterFacet, filter: FrontendChartFilter, hasSamplingChanged: boolean, filterTmpData: Partial<FilterTmpData>): void {
        if (this.chartFilterUtilsService.isDatePartFilter(filter)) {
            filterTmpData.dateFilterType = filter.dateFilterType;
            filterTmpData.dateFilterPart = filter.dateFilterPart;
        }
        const selectedValues = Object.keys(filter.selectedValues || {});
        const excludedValues = Object.keys(filter.excludedValues || {});
        const selectedValuesSet = new Set(selectedValues);
        const excludedValuesSet = new Set(excludedValues);
        if (filter.$isFromUrlQuery) {
            /*
             * Find common facet values between response and url query
             * if none, apply initial state (depending on all selected facet values)
             */
            const responseValues = new Set(responseFacet.values.map(({ id }) => id));
            const urlValues = (filter.excludeOtherValues ? selectedValues : excludedValues);
            const invalidValue = urlValues.find(value => !responseValues.has(value));

            if (urlValues.length && invalidValue) {
                filter.$warningMessage = `${filter.column} has no matching facet value '${invalidValue}'`;
            }
        }
        let hasAValueAlreadyBeenIncluded = false;
        filterTmpData.values = this.getAllTrackedValues(responseFacet, filter, hasSamplingChanged, selectedValues, excludedValues)
            .map(value => {
                const included = this.shouldFilterValueBeSelected(value, filter, selectedValuesSet, excludedValuesSet, hasAValueAlreadyBeenIncluded);
                if (!hasAValueAlreadyBeenIncluded) {
                    hasAValueAlreadyBeenIncluded = included;
                }
                return {
                    ...value,
                    included
                };
            });
    }

    private setDateRangeFilterValues(responseFacet: FilterFacet, filter: FrontendChartFilter, hasSamplingChanged: boolean, filterTmpData: Partial<FilterTmpData>): void {
        filterTmpData.dateFilterType = filter.dateFilterType;
        filterTmpData.timezone = filter.timezone;
        this.setNumericalFilterValues(responseFacet, filter, hasSamplingChanged, filterTmpData);
    }

    private setNumericalFilterValues(responseFacet: FilterFacet, filter: FrontendChartFilter, hasSamplingChanged: boolean, filterTmpData: Partial<FilterTmpData>): void {
        const minValue = filter.minValue != null ? filter.minValue : responseFacet.minValue;
        const maxValue = filter.maxValue != null ? filter.maxValue : responseFacet.maxValue;
        const isRangeInvalid = minValue > maxValue;
        const isMinInvalid = minValue > responseFacet.maxValue;
        const isMinOutOfRange = minValue < responseFacet.minValue;
        const isMaxInvalid = maxValue < responseFacet.minValue;
        const isMaxOutOfRange = maxValue > responseFacet.maxValue;
        // Filters from url query with out of range values should trigger a warning message.
        if (filter.$isFromUrlQuery) {
            if (isRangeInvalid) {
                filter.$warningMessage = `${filter.column} has invalid range: min is greater than max`;
            } else if (isMinInvalid || isMinOutOfRange) {
                filter.$warningMessage = `${filter.column} has invalid range: min is out of range`;
            } else if (isMaxInvalid || isMaxOutOfRange) {
                filter.$warningMessage = `${filter.column} has invalid range: max is out of range`;
            }
        }
        // If the sampling changed we override the filter min and max with the response min and max.
        if (typeof filter.minValue === 'number' && !hasSamplingChanged && !isRangeInvalid && !isMinInvalid && !(isMinOutOfRange && filter.$isFromUrlQuery)) {
            filterTmpData.minValue = filter.minValue;
        } else {
            filterTmpData.minValue = responseFacet.minValue;
        }
        if (typeof filter.maxValue === 'number' && !hasSamplingChanged && !isRangeInvalid && !isMaxInvalid && !(isMaxOutOfRange && filter.$isFromUrlQuery)) {
            filterTmpData.maxValue = filter.maxValue;
        } else {
            filterTmpData.maxValue = responseFacet.maxValue;
        }
    }

    private setRelativeDateFilterValues(filter: FrontendChartFilter, filterTmpData: Partial<FilterTmpData>): void {
        filterTmpData.dateFilterPart = filter.dateFilterPart;
        filterTmpData.dateFilterOption = filter.dateFilterOption;
        filterTmpData.minValue = filter.minValue;
        filterTmpData.maxValue = filter.maxValue;
        filterTmpData.dateFilterType = filter.dateFilterType;
    }

    /**
     * Returns the default exclude other values option value.
     */
    private getDefaultExcludeOtherValues(isAGlobalFilter?: boolean, facetValues?: FilterFacet.Val[] | FilterTmpDataValue[] | null | undefined): boolean {
        // Charts are in include mode by default.
        if (!isAGlobalFilter) {
            return false;
        }
        // Dashboards are in exclude mode if the facet contains less than 200 values or in include mode otherwise.
        return (facetValues || []).length < 200;
    }

    /**
     * Computes the exclude other values option value.
     */
    private computeExcludeOtherValues(filter: ChartFilter, responseFacet: FilterFacet): boolean {
        // excludeOtherValues can be already set if we're not dealing with a freshly created filter.
        if (filter.excludeOtherValues !== undefined) {
            return filter.excludeOtherValues;
        } else {
            return this.getDefaultExcludeOtherValues(filter.isAGlobalFilter, responseFacet.values);
        }
    }

    private getFacetValues(filterTmpData: FilterTmpData, included = true) {
        /*
         * filterTmpData.values can be null if we come from a range filter (eg a numerical filter converted as alphanum).
         * In this case, we should default to all values selected.
         */
        if (!filterTmpData.values) {
            return included ? null : {};
        }
        const values: Record<string, boolean> = {};
        filterTmpData.values.forEach(facetValue => {
            if (included ? facetValue.included : !facetValue.included) {
                values[facetValue.id] = filterTmpData.allValuesInSample || (!!facetValue.count && facetValue.count > 0);
            }
        });
        return values;
    }

    private switchFilterSelectionTypeToMultiSelect(filterTmpData: FilterTmpData, filters: ChartFilter[], filterIndex: number, getPivotResponseOptions: GetPivotRequestOptions) {
        if (this.chartFilterUtilsService.isDateRangeFilter(filterTmpData) || this.chartFilterUtilsService.isNumericalRangeFilter(filterTmpData)) {
            // if we come from a range we want to include all values in the range.
            this.convertRangeFilterToAlphanumFilter(ChartFilter.FilterSelectionType.MULTI_SELECT, filterTmpData, filters, filterIndex, getPivotResponseOptions);
        } else {
            const defaultExcludeOtherValues = this.getDefaultExcludeOtherValues(filterTmpData.isAGlobalFilter, filterTmpData?.response?.values);
            this.switchFilterSelectionTypeToAlphanum(filterTmpData, defaultExcludeOtherValues, ChartFilter.FilterSelectionType.MULTI_SELECT);
        }
    }

    private switchFilterSelectionTypeToSingleSelect(filterTmpData: FilterTmpData, filters: ChartFilter[], filterIndex: number, getPivotResponseOptions: GetPivotRequestOptions) {
        // keeping the first found value as included and de-selecting any other value
        if (filterTmpData.values && filterTmpData.values.length > 0) {
            // If there are values in filterTmpData.values, that means we come from a ALPHANUM_FACET, hence we can manually select the first value.
            let isAValueIncluded = false;
            for (const value of filterTmpData.values) {
                value.included = value.included && !isAValueIncluded;
                if (value.included) {
                    isAValueIncluded = true;
                }
            }
            // If we haven't included a value yet, include the first one.
            if (!isAValueIncluded) {
                filterTmpData.values[0].included = true;
            }
            this.switchFilterSelectionTypeToAlphanum(filterTmpData, true, ChartFilter.FilterSelectionType.SINGLE_SELECT);
        } else {
            // if there is no value in filterTmpData.values, that means we come from a NUMERICAL_FACET/DATE_FACET, we need to do a request to know the available values.
            this.convertRangeFilterToAlphanumFilter(ChartFilter.FilterSelectionType.SINGLE_SELECT, filterTmpData, filters, filterIndex, getPivotResponseOptions);
        }
    }

    private convertRangeFilterToAlphanumFilter(newSelectionType: AlphanumFilterSelectionType, filterTmpData: FilterTmpData, filters: ChartFilter[], filterIndex: number, getPivotResponseOptions: GetPivotRequestOptions) {
        if (!this.chartFilterUtilsService.isNumericalRangeFilter(filterTmpData) && !this.chartFilterUtilsService.isDateRangeFilter(filterTmpData)) {
            this.logger.error('Unsupported filter: date range or numerical range filter expected');
            return;
        }
        let minValue = filterTmpData.minValue ?? -Infinity;
        let maxValue = filterTmpData.maxValue ?? +Infinity;
        const filtersCopy = cloneDeep(filters);
        if (this.chartColumnTypeUtilsService.isDateColumnType(filterTmpData.columnType)) {
            // If we are dealing with a date then we have to match the precision of the default date part, which is year.
            if (minValue != null) {
                minValue = this.dateUtilsService.convertDateToTimezone(new Date(minValue), filterTmpData.timezone || 'UTC').getUTCFullYear();
            }
            if (maxValue != null) {
                maxValue = this.dateUtilsService.convertDateToTimezone(new Date(maxValue), filterTmpData.timezone || 'UTC').getUTCFullYear();
            }
            filtersCopy[filterIndex].dateFilterType = ChartFilter.DateFilterType.PART;
            filtersCopy[filterIndex].dateFilterPart = ChartFilter.DateFilterPart.YEAR;
        } else {
            filtersCopy[filterIndex].filterType = ChartFilter.FilterType.ALPHANUM_FACET;
        }
        filtersCopy[filterIndex].filterSelectionType = filterTmpData.filterSelectionType;
        filtersCopy[filterIndex].selectedValues = null;
        const onSuccess = (filterFacet: FilterFacet) => {
            let hasAValueAlreadyBeenIncluded = false;
            const numericalValues = filterFacet.values.map(value => Number(value.id)).sort((a, b) => a - b);
            delete filterTmpData.minValue;
            delete filterTmpData.maxValue;
            filterTmpData.values = numericalValues.map((value) => {
                // A null minValue or maxValue means the boundary is flexible and as such the compared value can be included.
                const isGreatherThanMin = value >= minValue;
                const isLessThanMax = value <= maxValue;
                const included = isGreatherThanMin && isLessThanMax && (newSelectionType === ChartFilter.FilterSelectionType.MULTI_SELECT || !hasAValueAlreadyBeenIncluded);
                if (included) {
                    hasAValueAlreadyBeenIncluded = true;
                }
                return { id: String(value), label: String(value), included };
            });
            this.switchFilterSelectionTypeToAlphanum(filterTmpData, true, newSelectionType);
        };
        this.getFilteredFilterFacet(filtersCopy, filterIndex, onSuccess, getPivotResponseOptions);
    }

    /**
     * Returns the filter facet of all available values given the state of the other filters.
     * @param filters                   - the list of all filters, including the one for which we want to  retrieve the facet.
     * @param filterIndex               - the index of the filters for which we want to retrieve the  facet in the filters list.
     * @param onSuccess                 - a callback to call when the facet has been succesfully fetched from the backend.
     * @param getPivotResponseOptions   - options allowing to make the call to the backend.
     */
    private getFilteredFilterFacet(filters: ChartFilter[], filterIndex: number, onSuccess: (facet: FilterFacet) => void, { projectKey, dataSpec, requestedSampleId, onError }: GetPivotRequestOptions) {
        const filter: ChartFilter = { ...filters[filterIndex] };
        if (this.chartFilterUtilsService.isAlphanumericalFilter(filter)) {
            if (filter.excludeOtherValues) {
                filter.selectedValues = null;
            } else {
                filter.excludedValues = {};
            }
        } else if (this.chartFilterUtilsService.isNumericalFilter(filter)) {
            filter.minValue = null;
            filter.maxValue = null;
        }
        const filtersCopy = [...filters];
        filtersCopy[filterIndex] = filter;
        const request = this.buildFiltersRequest(filtersCopy);
        this.filterFacetsService.getFilterFacets(projectKey, dataSpec, request, requestedSampleId)
            .success((data: { result: { pivotResponse: PivotTableResponse } }) => {
                const currentFilterFacet = data.result.pivotResponse.filterFacets[filterIndex];
                onSuccess(currentFilterFacet);
            })
            .error((data: fairAny, status: number, headers: Record<string, unknown>, config: Record<string, unknown>, statusText: string) => {
                if (data && data.hasResult && data.aborted) {
                    // Manually aborted => do not report as error
                } else if (onError) {
                    onError(data, status, headers, config, statusText);
                }
            });
    }

    private switchFilterSelectionTypeToAlphanum(filterTmpData: FilterTmpData, excludeOtherValues: boolean, filterSelectionType: AlphanumFilterSelectionType) {
        filterTmpData.filterSelectionType = filterSelectionType;
        filterTmpData.excludeOtherValues = excludeOtherValues;
        if (this.chartFilterUtilsService.isDateRangeFilter(filterTmpData) || this.chartFilterUtilsService.isRelativeDateFilter(filterTmpData)) {
            filterTmpData.dateFilterType = ChartFilter.DateFilterType.PART;
            filterTmpData.dateFilterPart = ChartFilter.DateFilterPart.YEAR;
        }
        if (filterTmpData.columnType !== AxisDef.Type.DATE) {
            filterTmpData.filterType = ChartFilter.FilterType.ALPHANUM_FACET;
        }
        delete filterTmpData.minValue;
        delete filterTmpData.maxValue;
    }

    private switchFilterSelectionTypeToRange(filterTmpData: FilterTmpData) {
        filterTmpData.filterSelectionType = ChartFilter.FilterSelectionType.RANGE_OF_VALUES;
        if (this.chartColumnTypeUtilsService.isDateColumnType(filterTmpData.columnType)) {
            filterTmpData.dateFilterType = ChartFilter.DateFilterType.RANGE;
        } else {
            filterTmpData.filterType = ChartFilter.FilterType.NUMERICAL_FACET;
        }

        delete filterTmpData.values;
    }

    private switchDateFilterTypeToRange(filterTmpData: FilterTmpData, filters: ChartFilter[], filterIndex: number, getPivotResponseOptions: GetPivotRequestOptions) {
        this.switchFilterSelectionType(ChartFilter.FilterSelectionType.RANGE_OF_VALUES, filterTmpData, filters, filterIndex, getPivotResponseOptions);
        delete filterTmpData.dateFilterOption;
        delete filterTmpData.dateFilterPart;
        filterTmpData.minValue = null;
        filterTmpData.maxValue = null;
    }

    private switchDateFilterTypeToRelativeRange(filterTmpData: FilterTmpData, filters: ChartFilter[], filterIndex: number, getPivotResponseOptions: GetPivotRequestOptions) {
        const dateFilterPart = (filterTmpData.dateFilterPart != null && this.AVAILABLE_RELATIVE_DATE_FILTER_DATE_PARTS.has(filterTmpData.dateFilterPart)) ? filterTmpData.dateFilterPart : ChartFilter.DateFilterPart.YEAR;
        if (filterTmpData.filterSelectionType !== ChartFilter.FilterSelectionType.RANGE_OF_VALUES) {
            this.switchFilterSelectionType(ChartFilter.FilterSelectionType.RANGE_OF_VALUES, filterTmpData, filters, filterIndex, getPivotResponseOptions);
        }
        filterTmpData.dateFilterPart = dateFilterPart;
        filterTmpData.dateFilterOption = ChartFilter.DateRelativeOption.THIS;
        filterTmpData.minValue = 1;
        filterTmpData.maxValue = 1;
        filterTmpData.dateFilterType = ChartFilter.DateFilterType.RELATIVE;
    }

    private switchDateFilterTypeToDatePart(filterTmpData: FilterTmpData, filters: ChartFilter[], filterIndex: number, getPivotResponseOptions: GetPivotRequestOptions) {
        // In the case of a relative date filter the min and max values cannot be reused for a date part filter, so we need to get rid of them or values may be missing.
        if (filterTmpData.dateFilterType === ChartFilter.DateFilterType.RELATIVE) {
            delete filterTmpData.minValue;
            delete filterTmpData.maxValue;
        }
        this.switchFilterSelectionType(ChartFilter.FilterSelectionType.MULTI_SELECT, filterTmpData, filters, filterIndex, getPivotResponseOptions);
    }
}
